<template>
  <v-app-bar app flat height="88" id="core-app-bar" outlined>
    <v-row class="d-flex align-baseline">
      <!-- <v-col
                  v-if="!$vuetify.breakpoint.xs "
                >
                  <v-img
                    v-if="responsive"
                    :height="$vuetify.breakpoint.xs?30:50"
                    contain
                    src="@/assets/bnc_logo.png"
                  />
                  <v-img
                    v-else
                    contain
                    src="@/assets/bnc.png"
                  />
                </v-col> -->
      <v-col class="d-flex justify-start ml-5">
        <v-btn
          absolute
          fab
          left
          :elevation="5"
          class="d-print-none ml-2"
          @click.stop="onClick"
          style="top: 30px; left: -20px"
          icon
          v-if="$vuetify.breakpoint.mdAndDown"
        >
          <v-icon color="blue accent-3"> mdi-menu </v-icon>
        </v-btn>
        <div class="app-bar float-right mt-1 ml-1">
          <span class="app-bar-title display-1 font-weight-medium"
            >{{ $t(title) }}
          </span>
          <br />
          <div class="app-bar-subtitle font-weight-regular">
            {{ $t('welcome') }}
            <span class="text-capitalize">{{ user.first_name }}</span>
            <span v-if="user.merchant">
              - {{ $t('company') }} {{ user.merchant.name }}
            </span>
          </div>
        </div>
      </v-col>

      <v-col
        class="d-flex align-end justify-end mt-5 mr-2"
        sm="2"
        md="2"
        lg="1"
      >
        <v-select
          v-model="selectLanguage"
          :items="languages"
          @change="switchLanguage"
          solo
          style="max-width: 75px"
        >
          <template v-slot:selection="{ item }">
            <img style="width: 22px" :src="item.image" />
          </template>
          <template v-slot:item="{ item }">
            <img style="width: 22px" :src="item.image" />
          </template>
        </v-select>
      </v-col>
    </v-row>

    <!-- 
    <v-toolbar-items class="d-print-none">
      <v-row align="center">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-slide-x-transition mode="in-out">
              <v-btn
                @click="print"
                v-if="isAllowedToPrint"
                class="mr-5"
                v-bind="attrs"
                v-on="on"
                icon
                large
                text
              >
                <v-icon color="blue accent-3">mdi-download-circle</v-icon>
              </v-btn>
            </v-slide-x-transition>
          </template>
          <span class="d-print-none">
            Télécharger ce rapport
          </span>
        </v-tooltip>
      </v-row>
    </v-toolbar-items> -->
  </v-app-bar>
</template>

<script>
// import * as html2canvas from 'html2canvas'

// import UploadFileBtn from "@/components/bnc/UploadFileBtn";
// Utilities
import { mapMutations } from 'vuex'

export default {
  data: () => ({
    title: null,
    responsive: false,
    DateTime: new Date().toUTCString(),
    languages: [
      {
        name: 'fr',
        image: require('@/assets/fr-48.png'),
      },
      {
        name: 'en',
        image: require('@/assets/uk-48.png'),
      },
    ],
    selectLanguage: {},
  }),
  watch: {
    $route(val) {
      this.title = val.name
    },
  },
  computed: {
    isAllowedToPrint() {
      return (
        this.$store.getters.isPreviewing || !this.$store.getters.isUserAdmin
      )
    },
    user() {
      return this.$store.state[
        this.$store.getters.isPreviewing ? 'preview' : 'user'
      ]
    },
  },
  mounted() {
    setInterval(() => {
      self.DateTime = new Date().toUTCString()
    }, 1000)
    this.onResponsiveInverted()
    window.addEventListener('resize', this.onResponsiveInverted)
    this.title = this.$route.name
    this.selectLanguage = {
      name: this.$store.state.language.name,
      image: this.$store.state.language.image,
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResponsiveInverted)
  },

  methods: {
    switchLanguage(v) {
      if (this.$i18n.locale === 'fr') {
        this.$i18n.locale = 'en'
      } else {
        this.$i18n.locale = 'fr'
      }

      this.$store.commit('setLanguage', v)
    },
    ...mapMutations('app', ['setDrawer', 'toggleDrawer']),
    onClick() {
      this.setDrawer(!this.$store.state.app.drawer)
    },
    onResponsiveInverted() {
      if (window.innerWidth < 991) {
        this.responsive = true
      } else {
        this.responsive = false
      }
    },
  },
}
</script>

<style type="scss">
/* Fix coming in v2.0.8 */
@media print {
  #core-app-bar {
    border: none !important;
    left: 0px !important;
    right: 0px !important;
    width: 100% !important;
    max-height: 88px !important;
    position: relative;
    padding: 0 !important;
    margin: 0px;
  }
  .app-bar {
    width: 100% !important;
  }
}

.app-bar {
  margin-left: -25px;
}
.app-bar-title {
  padding: 0 !important;
  color: #2d62ed;
}
.app-bar-subtitle {
  color: grey !important;
  font-size: 1rem;
}

#core-app-bar {
  border-left: 1px solid rgba(255, 255, 255, 0.1) !important;
  box-shadow: 0px 15px 10px -15px rgba(255, 255, 255, 0.1) !important;
  background-color: white !important;
}

#core-app-bar a {
  text-decoration: none;
}
</style>
